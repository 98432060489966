import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../layout/layout'

import '../../scss/sections/search-form.scss'

export default class Header extends React.Component {

    async callGraphQL(query) {
        try {
            let response = await fetch('https://products.zupr.services/graphql', {
                method: 'POST',
                body: JSON.stringify({
                    query: query,
                    variables: {},
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'Zupr-Env': 'prod',
                },
            })
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }

    async getDpStats() {
        let query = '{dpStats {brandCount,productCount}}'
        try {
            let json = await this.callGraphQL(query)
            this.setState({dp_stats: json['data']['dpStats'] })
        } catch (error) {
            console.log(error)
        }
    }


    async getProducts() {
        let barcode = document.getElementById("product_barcode").value

        if (barcode) {
            let query = `{omnisearch(barcode: "${barcode}") {dpId,barcode,title,brand,zuprId}}`
            try {
                let json = await this.callGraphQL(query)
                this.setState({product_results: json.data && json.data.omnisearch })
            } catch (error) {
                console.log(error)
            }
        }
    }

    async getBrands() {
        let titles = document.getElementById("brand_titles").value

        if (titles) {
            var title_list = titles.split(",")
            var last = title_list[title_list.length-1].trimLeft()
            if (last.length >= 3) {
                let query = `{brands(search: "${titles}", minProducts: 10) {title,brandId,productCount,syncedProductsCount}}`
                try {
                    let json = await this.callGraphQL(query)
                    this.setState({brand_results: json.data && json.data.brands })
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    async componentDidMount() {
        await this.getDpStats()
    }

    numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        } else {
            return 0
        }
    }

    state = {
        dp_stats: '',
        product_results: false,
        brand_results: false
    }

    render () {
        const { dp_stats, brand_results, product_results } = this.state

        return (

            <Layout>

                <Helmet>
                    <title>Quickstart: Hoe voeg ik snel en eenvoudig producten toe aan Zupr?</title>
                    <meta name="description" content="Het Zupr platform ontsluit productinformatie richting de consument. Deze productinformatie is in de meeste gevallen direct afkomstig van de merken, maar soms ook rechtstreeks van retailers." />
                </Helmet>

                <section className="text-default">
                    <div className="inner">
                        <div className="text">
                            <h2>Quickstart: Hoe voeg ik snel en eenvoudig producten toe aan Zupr?</h2>
                            <p>Producten toevoegen aan jouw online assortiment op Zupr kan op verschillende manieren. Deze quickstart biedt een overzicht van de verschillende manieren zodat je snel en eenvoudig het assortiment van jouw winkel kan vullen en beheren. Bij Zupr is veel mogelijk, van handmatig producten toevoegen tot een een geautomatiseerde koppeling!</p>
                            <h3>Onze productdatabase</h3>
                            <p>Zupr werkt met één grote productdatabase. Dagelijks voegen collega-ondernemers hier producten aan toe en ook merken koppelen met deze database om productinformatie in te laden. Onze filosofie is dat merken dé partij zijn om productinformatie voor jou beschikbaar te maken. Dan hoef jij alleen maar aan te geven welke producten je verkoopt, wat de prijs is en wat de actuele voorraad is.</p>
                            <p>Benieuwd of er producten van jouw merk(en) opgeslagen zijn in onze database? Lees dan snel verder!</p>
                        </div>
                    </div>
                </section>

                <section className="text-default grey">
                    <div className="inner">
                        <div className="text">
                            <h2>Beschikbare opties:</h2>
                            <div className="options">
                                <div className="block">
                                    <h3>1. Ik wil producten van een merk toevoegen</h3>
                                    <p>Check hoeveel producten van het merk bij Zupr bekend zijn en voeg deze toe.</p>
                                    <a href="#productinformatie-afkomstig-van-merken">Lees meer</a>
                                </div>
                                <div className="block">
                                    <h3>2. Ik beschik over een kassasysteem</h3>
                                    <p>Koppel jouw kassasysteem met Zupr zodat het assortiment, prijs en de voorraad automatisch bijgewerkt worden.</p>
                                    <a href="#heeft-u-de-beschikking-over-een-kassasysteem">Lees meer</a>
                                </div>
                                <div className="block">
                                    <h3>3. Ik verkoop mijn producten al online</h3>
                                    <p>Verkoop je al producten online via bijvoorbeeld Bol.com, Google Shopping of via een eigen webshop?</p>
                                    <a href="#ik-verkoop-mijn-producten-ook-online">Lees meer</a>
                                </div>
                                <div className="block">
                                    <h3>4. Ik beschik over een voorraad en prijs feed</h3>
                                    <p>Met een feed (.csv bestand) kan het assortiment, de prijs en voorraad automatisch worden bijgewerkt.</p>
                                    <a href="#voorraad-en-prijs-feed">Lees meer</a>
                                </div>
                                <div className="block">
                                    <h3>5. Ik wil zelf producten toevoegen</h3>
                                    <p>Voeg handmatig producten toe die nog niet beschikbaar zijn via de Zupr database.</p>
                                    <a href="#zelf-producten-toevoegen">Lees meer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <a name="productinformatie-afkomstig-van-merken" />
                <section className="text-default divider">
                    <div className="inner">
                        <div className="text">
                            <h2>1. Ik wil producten toevoegen uit de Zupr productdatabase</h2>
                            <p>Verkoop je producten van een merk welke ook door andere retailers verkocht worden? Voer dan hieronder het merk in om te kijken hoeveel producten we van het merk reeds in onze Zupr-database hebben staan.</p>
                            <p>Indien je productinformatie in een externe databron is opgeslagen, is deze waarschijnlijk al aanwezig in het Zupr platform of kan deze eenvoudig toegevoegd worden.</p>
                            <h3>Onze bronnen*:</h3>
                            <ul className="check-list">
                              <li><a href="https://www.gs1.nl/sectorafspraken-over-standaarden/betrouwbare-productdata-levensmiddelen-en-drogisterij-agf-en/gs1-13">GS1 datalink</a></li>
                              <li>FashionCloud</li>
                              <li>Channable</li>
                              <li>Icecat</li>
                              <li>Centraal boekhuis en Titelbank</li>
                            </ul>
                            <p><small>* Deze bronnen worden continue aangevuld</small></p>
                            <h3>Zoek in {this.numberWithCommas(dp_stats.brandCount)} merken</h3>
                            <p>Vul hieronder maximaal 10 merknamen in, gescheiden door een komma en klik op "Zoek merk".</p>
                            <div className="search-form">
                                <input onKeyUp={() => this.getBrands()} id="brand_titles" name="brand_titles" type="text" placeholder="Bijvoorbeeld: ViaVai, Rehab, Lego" />
                                <button onClick={() => this.getBrands()} type="submit" className="btn-medium success">Zoek merk</button>
                            </div>
                            {brand_results && (
                                <div className="table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Merk</th>
                                                <th>Aantal producten</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                            {brand_results.map((value) =>
                                            <tr key={value.title}>
                                                <td>{value.title}</td>
                                                <td>{this.numberWithCommas(value.productCount)}</td>
                                                <td>
                                                {value.syncedProductsCount == 0 && (
                                                    <>&nbsp;</>
                                                )}
                                                {value.brandId.length === 0 && value.syncedProductsCount > 0 && (
                                                    <a target="_blank" href={`https://www.zupr.nl/producten?search=${value.title}`} rel="noreferrer">Zoek aanbod</a>
                                                )}
                                                {value.brandId.length > 0 && value.syncedProductsCount > 0 && (
                                                    <a target="_blank" href={`https://www.zupr.nl/producten?brand=${value.brandId.join(',')}`} rel="noreferrer">Bekijk aanbod</a>
                                                )}
                                                </td>
                                            </tr>
                                          )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <h3>Jouw merk ook vindbaar via Zupr?</h3>
                            <p>Wil je als merk productdata vrijblijvend doorgeven aan Zupr of wil je het merk laten weten hoe zij productdata kunnen aanleveren? Kijk dan op onze pagina over het maken van een <a href="/nl/datakoppeling">datakoppeling</a>.</p>
                        </div>
                    </div>
                </section>

                <a name="heeft-u-de-beschikking-over-een-kassasysteem" />
                <section className="text-default divider">
                    <div className="inner">
                        <div className="text">
                            <h2>2. Ik beschik over een kassasysteem</h2>
                            <p>Met een kassasysteem worden het assortiment, de prijs en voorraad automatisch bijgewerkt.</p>
                            <div className="double-table">
                                <div className="table">
                                    <table className="tarif available">
                                        <tbody>
                                            <tr>
                                                <th>Ondersteund</th>
                                            </tr>
                                            <tr>
                                                <td><strong>StoreContrl, Valk, VJA en MPlusKassa</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Je kunt je verkooplocatie 'zero-maintenace' op Zupr tonen. Wij koppelen met deze kassasystemen en zodat het assortiment, de verkoopprijs en de voorraad automatisch wordt bijgewerkt!</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table">
                                    <table className="tarif not-available">
                                        <tbody>
                                            <tr>
                                                <th>In ontwikkeling</th>
                                            </tr>
                                            <tr>
                                                <td><strong>Winstore, Afosto, Zettle, Shopify en LightSpeed</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Deze kassasystemen worden op dit moment nog niet ondersteund. Neem contact op en informeer naar de planning en mogelijkheden.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p><small>* Let op, kassasysteemleveranciers rekenen mogelijk extra (maandelijkse) kosten voor een kassakoppeling.</small></p>
                            <h3>Ik gebruik een ander kassasysteem, namelijk...</h3>
                            <p>Neem contact op en informeer naar de mogelijkheden. Het is onze ambitie om zoveel mogelijk systemen te ondersteunen, maar helaas voldoen niet alle kassasystemen aan onze voorwaarden. Belangrijke voorwaarden zijn dat het kassasysteem extern benaderbaar moet zijn (bijvoorbeeld via een externe API en in de cloud) én het systeem moet minimaal beschikken over barcodes, prijs en voorraad.</p>
                            <a className="btn btn-demo btn-light" href="mailto:info@zupr.nl?subject=Ik wil graag meer informatie">Neem contact op</a>
                        </div>
                    </div>
                </section>

                <a name="ik-verkoop-mijn-producten-ook-online" />
                <section className="text-default divider">
                    <div className="inner">
                        <div className="text">
                            <h2>3. Ik verkoop mijn producten al online</h2>
                            <p>Als je op dit moment al producten verkoopt op bijvoorbeeld Bol.com, Google shopping, Facebook of via je eigen webshop, kan in sommige gevallen op basis van deze data ook op Zupr het assortiment up-to-date gehouden worden.</p>
                            <p>Neem contact op over de mogelijkheden van het gebruik van je webshop of een feed voor het up-to-date houden van het assortiment op Zupr. Deze feeds worden ook vaak gebruikt als bron voor optie 4.</p>
                            <a className="btn btn-demo btn-light" href="mailto:info@zupr.nl?subject=Ik wil graag meer informatie">Neem contact op</a>
                        </div>
                    </div>
                </section>

                <a name="voorraad-en-prijs-feed" />
                <section className="text-default divider">
                    <div className="inner">
                        <div className="text">
                            <h2>4. Ik beschik over een voorraad en prijs feed</h2>
                            <p>De meest simpele oplossing voor het geautomatiseerd bijwerken van het assortiment is het doorgeven van de barcode, prijs en voorraad in bijvoorbeeld een .csv bestand. De werkwijze van deze koppelmethode is dat de barcodes uit een .csv bestand worden opgezocht in de Zupr producten database. De producten die gevonden zijn worden aan het assortiment toegevoegd en voorzien van de voorraad en verkoopprijs. Met het onderstaande formulier krijgt u een indicatie van welke producten reeds in de database beschikbaar zijn.</p>
                            <h3>Zoek in {this.numberWithCommas(dp_stats.productCount)} producten</h3>
                            <p>Vul hieronder de barcode van een product in en klik op "zoek product".</p>
                            <div className="search-form">
                                <input onKeyPress={this.onKeyUp} id="product_barcode" name="product_barcode" type="text" placeholder="Bijvoorbeeld: 8718921016766" />
                                <button onClick={() => this.getProducts()} type="submit" className="btn-medium success">Zoek product</button>
                            </div>
                            {product_results && (
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Productnaam</th>
                                            <th>Merk</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        {product_results.map((value) =>
                                            <tr key={value.dpId}>
                                                <td>{value.title}</td>
                                                <td>{value.brand}</td>
                                                <td>{value.zuprId && (<a target="_blank" href={`https://www.zupr.nl/${value.zuprId}`} rel="noreferrer">Bekijk product</a>)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                            <p>Het .csv bestand hoeft enkel 3 kolommen te bevatten; 'barcode', 'stock_count' en 'price' en kan op een url beschikbaar gemaakt worden. Neem contact op om deze mogelijkheid te bespreken. Zowel in de kolommen en de manier van aanleveren zijn vele variaties te maken.</p>
                            <a className="btn btn-demo btn-light" href="mailto:info@zupr.nl?subject=Ik wil graag meer informatie">Neem contact op</a>
                        </div>
                    </div>
                </section>

                <a name="zelf-producten-toevoegen" />
                <section className="text-default">
                    <div className="inner">
                        <div className="text">
                            <h2>5. Ik wil zelf producten toevoegen</h2>
                            <p>Indien de producten die je wilt toevoegen niet beschikbaar zijn in Zupr, omdat een merk deze data bijvoorbeeld niet heeft aangeleverd, kan je deze producten handmatig toevoegen. Wil je meer weten over het handmatig toevoegen van producten? Bekijk dan hier onze video’s en <a rel="noopener noreferrer" target="_blank" href="https://support.zupr.nl/hc/nl/sections/360003815220-Ik-wil-het-assortiment-van-mijn-winkel-beheren">kennisartikelen</a> over het toevoegen van producten aan de productdatabase van Zupr.</p>
                            <h3>Volledigheid assortiment</h3>
                            <p>Het is natuurlijk het mooist om je volledige assortiment online te tonen. Echter adviseren wij retailers vaak dat het assortiment ook uit plus minus 30 zelf zorgvuldig ingevoerde producten kan bestaan, waarbij bijvoorbeeld zelfs de voorraad actief wordt bijgehouden en de producten bestelbaar zijn. Consumenten ervaren dit namelijk als prettiger, dan wanneer er 2000 producten zonder beschrijving en duidelijke voorraad en prijs in het assortiment getoond worden.</p>
                        </div>
                    </div>
                </section>

                <section className="text-center grey">
                    <div className="inner">
                        <div className="text">
                            <h3>Jouw assortiment snel en eenvoudig tonen op Zupr?</h3>
                            <p>Ben je overtuigd van de mogelijkheden van Zupr en wil je dat jouw klanten het assortiment van je winkel makkelijk online vinden? Meld je locatie dan gratis aan en binnen 5 minuten heb je de eerste producten online staan.</p>
                            <a className="btn btn-demo" href="/nl/aanmelden">Maak een gratis account aan</a>
                        </div>
                    </div>
                </section>

                <section className="contact-boost">
                    <div className="inner">
                        <h2>Vragen?</h2>
                        <p>Voor vragen of toelichting neem je contact opnemen met het Zupr team.</p>
                        <p>
                            <a href="mailto:support@zupr.nl?subject=Interesse in Zupr" className="email">support@zupr.nl</a>
                            <a href="tel:+31854011300" className="telephone">+31 (0)85 401 13 00</a>
                        </p>
                    </div>
                </section>

        </Layout>
        )
    }
}
